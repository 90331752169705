@import 'variables';

.unitSelector {
  background-color: $color-grey;
  position: relative;
  padding-bottom: 4em;
  background-image: url('../images/app/compass.svg');
  background-repeat: no-repeat;
  background-size: 2.5rem;
  background-position: $col-11 7rem;

  @media (min-width: $break-md) {
    background-position: $col-1 center;
  }

  .subheader {
    margin-bottom: 1em;
  }
  
  .buttonWrapper {
    margin-bottom: 1em;
    display: none;

    .toggleZoom {
      cursor: pointer;
      background-image: url('../images/app/zoom.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 1.5em;
      height: 1.5em;
    }

    @media (max-width: $break-md) {
      display: inline-block;
    }
  }

  .container {
    .imageWrapper {
      width: $col-10;
      margin: 0 auto;
      position: relative;
      transition: all 0.5s;
      
      .mapArea {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-pink-overlay;
      }
      
      .name {
        position: absolute;
        display: block;
        background-color: $color-text;
        color: white;
        padding: 4px;
        width: 3em;
        text-align: center;
        transition: all 0.5s;
        z-index: 10;
  
        &.available {
          cursor: pointer;
  
          &:hover {
            color: $color-pink;
            transform: scale(1.1);
          }
        }
  
        &::after {
          content: '';
          position: absolute;
          display: inline-block;
          bottom: -9px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid $color-text;
          left: 7px;
        }
  
        &.unavailable {
          background-color: $color-grey;
          color: $color-text;
          cursor: default;
  
          &::after {
            border-top-color: $color-grey;
          }
        }
      }

      @media (max-width: $break-md) {
        width: 100%;
        margin-bottom: 10em;
        
        .name {
          font-size: 0.5em;
        }
      }
    }
  }

  &.zoomed {
    .container {
      overflow: auto;
      margin-left: -9%;
      margin-right: -9%;

      .imageWrapper {
        width: 150%;
        margin-left: 9.6%;
        margin-right: 9.6%;
      }

      .name {
        font-size: 1em;
      }
    }

    .toggleZoom {
      background-image: url('../images/app/zoom-out.png');
    }
  }

  .sectionTypeLegend {
    position: absolute;
    right: $col-1;
    bottom: 2em;

    .inputContainer {
      margin-bottom: 0.5em;

      input {
        -webkit-appearance: none;
        width: 1.5em;
        height: 1.5em;
        background-color: white;
        border-radius: 50%;
        margin-right: 1em;
        border: 1px solid $color-text;
        vertical-align: middle;
        outline: none;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          background-color: $color-pink;
        }

        &:checked {
          position: relative;

          &::before {
            background-color: black;
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            left: 2px;
            top: 2px;
          }
        }

        &[type=checkbox] {
          border-radius: 0;

          &:checked {
            &::before {
              content:'\2713';
              font-size: 1.3em;
              top: -0.1em;
              left: 0.2em;
              background-color: transparent;
              border-radius: 0;
            }
          }
        }
      }

      label {
        font-weight: bold;
        display: inline-block;
        transform: translateY(0.2em);
      }
    }

    @media (max-width: $break-md) {
      margin-top: 10em;
      // margin-bottom: 2em;
      left: $col-1;
    }
  }
}