@import 'variables.scss';

.plot {
  transition: color 0.5s;
  background-color: white;
  padding: 0.5em;

  &.current {
    color: $color-pink;
  }
}