@import 'variables';

section.contactForm {
  background: white url("../images/app/logo-black.png") no-repeat;
  background-position-x: $col-11;
  background-position-y: calc(100% - 1em);
  background-size: 6em;

  .header {
    margin-bottom: 1em; 
  }

  .inputContainer {
    padding: 0.5em 0;

    input {
      background-color: #eee;
      color: $color-text;
      padding: 1em;
      font-size: 0.8em;
      border-radius: 0.5em;
      font-weight: bold;
      border: none;
      width: 100%;
      max-width: 28em;

      &[type=submit] {
        background-color: $color-text;
        color: white;
        padding: 1em 2em;
        width: auto;
      }
    }
  }

  .message {
    color: darken($color-grey, 30);
    margin-bottom: 2em;

    b {
      color: $color-text;
    }
  }

  @media (max-width: $break-md) {
    padding-bottom: 6em;
  }
}