@import 'variables';

.sectionSelector {
  position: relative;
  height: 38em;
  background-color: white;

  .currentPlot {
    position: absolute;
    z-index: 1;

    .imageWrapper {
      margin: 0 auto;
      height: 30em;
      transform: translateX(-3em); // Building not centered in image
      
    }
    @media (max-width: $break-sm) {
      position: relative;
      margin-bottom: 1em;

      .imageWrapper {
        height: auto;
        transform: translateX(-1em); // Building not centered in image
      }
    }
  }

  .plots {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 25em;
    position: relative;
    z-index: 2;
    margin-top: 4.7em;

    .plotWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: $col-6;

      &:first-of-type {
        order: 2; // Swapping order of buildings left/right

        .plot {
          order: 2;
        }

        .sections {
          margin-right: 1em;
          text-align: right;
        }
      }

      &:last-of-type {
        order: 1;

        .sections {
          margin-left: 1em;
          text-align: left;
        }
      }

      .sections {
        flex-grow: 1;
      }
    }
  }

  @media (max-width: $break-sm) {
    height: auto;

    .plots {
      display: block;
      margin-top: 0;
      height: auto;

      .plotWrapper,
      .plotWrapper:first-of-type,
      .plotWrapper:last-of-type {
        display: block;
        width: 100%;

        .plot {
          padding-left: 0;
        }

        .sections {
          text-align: left;
          margin-left: 0;

          .section {
            display: inline-block;
            margin-right: 0.5em;
            padding: 0.35em 0.25em 0.5em 0.25em;
            width: 1.75em;
            height: 1.75em;
            background-color: $color-grey;
            border-radius: 0.25em;

            &.current {
              background-color: $color-text;
            }

            .button {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}