@import 'variables';

section.currentUnit {
  background: $color-text;
  color: white;
  padding-bottom: 3em;

  @media (max-width: $break-md) {
    padding-bottom: 6em;
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
  
    .imageWrapper {
      width: $col-7;
      margin-right: $col-1;
    }
  
    .specs {
      width: $col-4;
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0.8em;
  
      li {
        padding: 2em 2em;
        border-bottom: 2px solid white;
        border-radius: 1px;
        font-weight: bold;
  
        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    @media (max-width: $break-md) {
      flex-direction: column;

      .specs,
      .imageWrapper {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
