@import 'variables';

.App {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  header {
    text-align: center;
    padding: 1em;
    border-bottom: 1px solid $color-text;
    text-transform: uppercase;
    background-color: white;
  }

  section {
    padding: 1em $col-1;
    position: relative;

    .header {
      margin-bottom: 0;
    }

    .subheader {
      font-size: 1.25em;
      margin-bottom: 2em;
    }

    &:not(.sectionSelector):before {
      display: block;
      content: '';
      width: 0; 
      height: 0; 
      border-left: 2vw solid transparent;
      border-right: 2vw solid transparent;
      border-bottom: 3vw solid $color-grey;
      position: absolute;
      top: -3vw;
      left: $col-1;

      @media (max-width: $break-md) {
        border-left-width: 2em;
        border-right-width: 2em;
        border-bottom-width: 3em;
        top: -3em;
      }
    }

    &.currentUnit {
      &:before {
        border-bottom-color: $color-text;
      }
    }

    &.contactForm {
      &:before {
        border-bottom-color: white;
      }
    }

    @media (max-width: $break-sm) {
      &.sectionSelector {
        padding-bottom: 6em;
      }
    }
  }

  .quotation section {
    &:first-of-type {
      &::before {
        border: none;
      }
    }
  }

  footer.appFooter {
    background-color: $color-text;
    height: calc(100vh - 38em);
    background: $color-text url("../images/app/logo-white.png") no-repeat;
    background-position-x: $col-11;
    background-position-y: 1em;
    background-size: 6em;
    padding: 1em $col-1;

    @media (max-width: $break-sm) {
      height: 5em;
    }

    .toggleQuotation {
      cursor: pointer;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 1em;

      &:hover {
        color: $color-pink;
      }
    }

    &.quotationMode {
      border-top: 2px solid $color-grey-light;
    }
  }
}

.splash {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 10em;
    height: 6em;
    background-image: url('../images/app/logo-black.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  p {
    width: 50vw;
    min-width: 15em;
    max-width: 20em;
    color: darken($color-grey, 30);

    b {
      color: $color-text;
    }

    .details {
      font-size: 0.8em;
      color: darken($color-grey, 10);
    }
  }

  &.loading {
    text-align: center;
  }
}