@import 'variables.scss';

.login {
  min-height: 38em;
  background-color: $color-text;
  color: white;
  padding: 2em $col-1;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 1.5em;
    padding-bottom: 3em;
    margin-bottom: 2em;
    background-image: url(../images/app/user.png);
    background-repeat: no-repeat;
    background-size: 2.5em;
    background-position-x: center;
    background-position-y: bottom;
  }

  input {   
    outline: none;
    border: none;

    &[type=text],
    &[type=password] {
      padding: 0.5em 0;
      margin-bottom: 2em;
      width: 20em;
      background-color: transparent;
      color: white;
      border-top: 1px solid $color-pink;
    }

    &[type=submit] {
      background-color: $color-pink;
      color: $color-text;
      border-radius: 1em;
      padding: 1em 1.5em;
      font-weight: bold;
    }
  }

  .message {
    margin-top: 1em;
  }
}