@import 'variables.scss';

.section {
  width: 100%;
  height: 1.32em;
  overflow: hidden;
  transition: all 0.5s;
  padding-bottom: 0.25em;
  cursor: pointer;
  
  .button {
    font-size: 0.9em;
    display: inline-block;
    transition: all 0.5s;
    background-color: $color-grey;
    color: white;
    text-transform: uppercase;
    width: 1.5em;
    text-align: center;
  }

  &:hover {
    background-color: $color-pink-overlay;

    .button {
      color: $color-pink;
    }
  }

  &.current {
    .button {
      color: $color-pink;
      background-color: $color-text;
    }
  }
}