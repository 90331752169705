// Breakpoints
$break-sm:                      480px;
$break-md:                      600px;
$break-lg:                      1100px;
$break-xl:                      2000px;

// 12 column grid
$col-1: 8.33%;
$col-2: 16.66%;
$col-3: 25%;
$col-4: 33.33%;
$col-5: 41.66%;
$col-6: 50%;
$col-7: 58.33%;
$col-8: 66.66%;
$col-9: 75%;
$col-10: 83.33%;
$col-11: 91.66%;
$col-12: 100%;

// Absolute units
$margin-sm: 1em;
$margin-md: 2em;
$margin-lg: 3em;


// Colours
$color-pink:            rgb(240, 194, 179);
$color-pink-overlay:    rgba(240, 194, 179, 0.4);
$color-grey:            rgb(217, 211, 206);
$color-grey-light:      rgb(245, 244, 243);
$color-grey-dark:       rgb(49,45,46);
$color-text:            rgb(28, 26, 25);

// Typography
$font-family-normal:     'Gotham', sans-serif;