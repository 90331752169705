@import 'variables.scss';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family-normal;
  color: $color-text;
  background-color: #f6f6f6;
}

input,
textarea,
select {
  font-family: $font-family-normal;
}

.imageWrapper {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/GothamRnd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}


