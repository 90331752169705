@import 'variables.scss';

.quotation {
  min-height: 38em;
  background-color: white;
  padding: 2em 0 0 0;

  section {
    .inputContainer {
      select,
      input,
      .value {
        border-radius: 8px;
        border: none;
        padding: 0.5em 1em;
        color: white;
        font-weight: bold;
        margin-bottom: 0.5em;
        font-size: 1em;
        width: 100%;
        background-color: $color-text;
        outline: none;
      }
      
      label {
        font-weight: bold;
        padding: 0.25em 1em;
        margin-bottom: 0.5em;
        display: block;
      }

      &.submit {
        text-align: center;
        margin-top: 2em;

        input[type=submit] {
          transition: all 0.5s;
          background-color: $color-pink;
          color: $color-text;
          width: auto;
          padding-left: 2em;
          padding-right: 2em;
          cursor: pointer;

          &:hover {
            background-color: $color-grey-dark;
            color: white;
          }
        }

        @media (min-width: $break-lg) {
          text-align: right;
        }
      }
    }
  
    fieldset {
      border: none;
      padding: 0;
      margin: 0 0 1em 0;
      padding: 0.5em 0 1em 0;
  
      &:nth-of-type(2) {
        background-color: $color-grey-light;
        margin-left: -10%;
        margin-right: -10%;
        padding-left: $col-1;
        padding-right: $col-1;
        padding-bottom: 4em;
  
        .inputContainer {
          border-bottom: 2px solid darken($color-grey-light, 15);
          margin-top: 1em;
  
          &:last-of-type {
            border: none;
          }

          &.total {
            margin-top: 2em;
          }
        }
  
        label {
          color: $color-text;
        }
  
        select,
        input,
        .value {
          color: $color-text;
          background-color: transparent;
          font-weight: normal;
        }
      }
    }

    &:nth-of-type(2) {
      background-color: $color-text;
      color: white;
      margin-top: -2em;

      &::before {
        border-bottom-color: $color-text;
      }

      .inputContainer {
        select {
          background-color: white;
          color: $color-text;
        }
      }

      fieldset:nth-of-type(2) {
        background-color: $color-grey-dark;
        padding-bottom: 2em;
        
        .inputContainer {
          border-bottom-color: white;

          label {
            color: white;
          }

          select,
          .value {
            color: white;
          }

          input {
            background-color: $color-grey-light;
            width: 50%;
          }
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      // font-size: 0.8em;

      li {
        border-bottom: 1px solid $color-grey;
        padding: 0.5em 0;

        span {
          width: 50%;
          display: inline-block;
        }

        &:first-of-type {
          color: $color-grey;
        }
      }
    }
  }

  @media (min-width: $break-lg) {
    padding-top: 0;

    section {
      display: flex;
      padding-right: 0;
      padding-top: 0;

      &:nth-of-type(1) {
        fieldset {
          padding-top: 2em;

          // Select unit and dates
          &:nth-of-type(1) {
            width: $col-7;
            padding-right: $col-1;
          }

          // General info section
          &:nth-of-type(2) {
            width: $col-5;
            padding-left: 1em;
            padding-right: 1em;
            margin: 0;
          }
        }
      }

      &:nth-of-type(2) {
        flex-wrap: wrap;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;

        fieldset {
          // Select payment
          &:nth-of-type(1) {
            width: $col-12;
            padding-right: $col-6;
            padding-top: 2em;
            order: 1;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            &::after {
              display: block;
              content:'';
              width: $col-5;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              background-color: $color-grey-dark;
            }
          }

          // Percentages
          &:nth-of-type(2) {
            width: $col-5;
            order: 3;
            padding-left: 1em;
            padding-right: 1em;
            margin: 0;
            margin-top: -5.5em;
            z-index: 2;
          }

          // Monthly payments table
          &:nth-of-type(3) {
            width: $col-7;
            padding-right: $col-1;          
            order: 2
          }
        }
      }
    }
  }
}